import React, { useEffect } from "react";
import SEO from "@src/components/SEO";
import { logEvent } from "@src/utils/logClient";
import Gameplay2025 from "@src/assets/images/game2025.jpg";
import styled from "@emotion/styled";
import Mojo from "@src/assets/images/gametime2025/mojo.png";
import Scenario from "@src/assets/images/gametime2025/scenario.png";

export const StyledPage = styled.div`
  margin: 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "HafferXH", sans-serif;
  text-align: center;
  background-image: url("${Scenario}");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  h1 {
    color: #000;
    font-feature-settings: "liga" off, "clig" off;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #fff;
    font-size: 48px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    text-transform: uppercase;
    margin-top: 0;
  }

  .buttonContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 80px;
    gap: 60px;
  }

  .mojoSpan {
    font-family: "HafferXH";
    margin: 10px;
    max-width: 500px;
    font-size: 32px;
    padding: 20px 50px;
    position: relative;
    border-radius: 40px;
    border: 1px solid #f7f7f7;
    background: #fff;
    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .mojoSpan img {
    margin-top: -80px;
    width: 200px;
  }

  .mojoSpan .name {
    padding-block: 14px;
    font-size: 20px;
  }

  .mojoSpan .number {
    position: absolute;
    top: -30px;
    right: -60px;
    border-radius: 60px;
    border: 7px solid #fff;
    background: #1c7f3d;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 16px 32px;
    color: #fff;
  }

  .mojoSpan .number:empty {
    display: none;
  }
`;

const GamePlayPage = () => {
  useEffect(() => {
    logEvent({
      eventName: "web.external.gametime2025.page-load",
      eventValue: window.location.href,
    });
  }, []);

  return (
    <>
      <SEO title="Who will win?!" description="Vote for your favorite!" image={Gameplay2025} />
      <StyledPage>
        <div className="buttonContainer">
          <span className="mojoSpan">
            <img src={Mojo} alt="Mojo" />
            <h1>Mojo Wins!</h1>
            <span className="name">Mojo takes the trophy! Come back next year for another showdown 🏈</span>
            <span className="number">4,458,776</span>
          </span>
        </div>
      </StyledPage>
    </>
  );
};

export default GamePlayPage;
